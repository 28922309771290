// eslint-disable-next-line no-redeclare,no-unused-vars
function checkWhere(row, where, param = {}) {
  var success = false;
  if (!param.noConvert) {
    where = Object.assign({}, where);
    where = convertWhere(where, param);
  }
  $.each(where, function (key, value) {
    if (key == 'converted') return;
    var result = checkWhereValue(
      param.object,
      row,
      key,
      value,
      param.nullAsEmpty
    );
    if (result === null) return;

    success = result;
    if (!success) return false;
  });
  return success;
}
